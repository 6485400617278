import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import SEO from '@/components/SEO';

import {
  Wrapper,
  Container,
  BreadCrumbsWrapper,
  SectionWrapper,
} from '@/styles/pages/politicas-de-privacidade';

import HeroSection from '@/components/Pages/PoliticasDePrivacidade/HeroSection';
import Section from '@/components/Pages/PoliticasDePrivacidade/Section';
import { CMS_URL } from '@/config';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'Políticas de privacidade' },
];

const PoliticasDePrivacidadePage = (): JSX.Element => {
  const pageQuery = graphql`
    query {
      directus {
        site_pages(
          filter: { slug: { _contains: "politicas-de-privacidade" } }
        ) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(pageQuery);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Políticas de privacidade"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <Wrapper>
          <Container>
            <SectionWrapper>
              <HeroSection />
            </SectionWrapper>

            <BreadCrumbsWrapper>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsWrapper>

            <SectionWrapper>
              <Section />
            </SectionWrapper>
          </Container>
        </Wrapper>
      </Layout>
    </>
  );
};

export default PoliticasDePrivacidadePage;
