import React from 'react';
import { HeroSection } from './styles';

const HeroSectionComponent = (): JSX.Element => {
  return (
    <HeroSection>
      <h1>Política de segurança da informação</h1>
    </HeroSection>
  );
};

export default HeroSectionComponent;
