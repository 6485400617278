import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1380px;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const BreadCrumbsWrapper = styled.div`
  width: 100%;
  max-width: 1380px;

  margin: 40px;

  @media (max-width: 1440px) {
    margin-left: 29px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const SectionWrapper = styled.div`
  width: 100%;
  max-width: 1380px;
`;
