import styled from 'styled-components';

export const Section = styled.section`
  margin: 32px 147px;
  max-width: 830px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    width: 830px;

    margin-top: 60px;
  }

  h2 + p {
    margin-top: 5px;
  }

  @media (max-width: 1144px) {
    margin: 32px 100px;
  }

  @media (max-width: 1044px) {
    margin: 32px 50px;
  }

  @media (max-width: 950px) {
    margin: 32px 30px;
  }

  @media (max-width: 500px) {
    margin: 32px 16px;
  }
`;

export const SectionTop = styled.div`
  h2 {
    font-size: 16px;
    font-weight: 700;

    text-transform: uppercase;

    margin-top: 32px;
  }

  h2:first-child {
    margin-top: 0;
  }
`;

export const SectionBottom = styled.div`
  margin-top: 5px;

  p {
    margin-top: 25px;
  }

  p:first-child {
    margin-top: 0;
  }

  h3 + p {
    margin-top: 5px;
  }

  h3 {
    margin-top: 32px;
  }
`;
