import React from 'react';
import { Section, SectionBottom, SectionTop } from './styles';

const SectionComponent = (): JSX.Element => {
  return (
    <Section>
      <SectionTop>
        <h2>Objetivos</h2>
        <p>
          Conforme definição da norma NBR ISO/IEC 17799, a informação é um ativo
          que, como qualquer outro ativo importante para negócios, tem um valor
          para a organização e, consequentemente, necessita ser adequadamente
          protegida. Este Manual tem por objetivo definir uma Política de
          Segurança da Informação da Transporte Generoso que atenda essas
          necessidades.
        </p>
        <h2>Abrangência</h2>
        <p>
          A política de segurança se aplica aos empregados, estagiários,
          contratados, prestadores de serviços, parceiros e fornecedores que
          utilizam as informações da Empresa
        </p>
        <h2>Comprometimento da direção</h2>
        <p>
          A direção da Transporte Generoso está comprometida em proteger todos
          os ativos ligados à Tecnologia da Informação – TI.
        </p>
        <h2>Segurança da informação</h2>
        <p>
          As medidas de segurança da informação, na Transporte Generoso, visam
          proteger a informação de diversos tipos de ameaças, para garantir a
          continuidade dos negócios, minimizando os danos e maximizando o
          retorno dos investimentos e as oportunidades de negócio.
        </p>
      </SectionTop>
      <h3>
        A segurança da informação é aqui caracterizada pela preservação de:
      </h3>
      <SectionBottom>
        <p>
          a) Confidencialidade – a garantia de que a informação é acessível
          somente a pessoas com acesso autorizado;
        </p>
        <p>
          b) Integridade – a salvaguarda da exatidão e completeza da informação
          e dos métodos de processamento;
        </p>
        <p>
          c) Disponibilidade – a garantia de que os usuários autorizados
          obtenham acesso à informação e aos ativos correspondentes, sempre que
          necessário.
        </p>
        <h3>Riscos</h3>
        <p>
          Os riscos típicos em Tecnologia de Informação que esta política
          procura evitar são:
        </p>
        <p>a) Revelação de informações sensíveis;</p>
        <p>b) Modificações indevidas de dados e programas;</p>
        <p>c) Perda de dados e programas;</p>
        <p>d) Destruição ou perdas de recursos e instalações;</p>
        <p>e) Interdições ou interrupções de serviços essenciais;</p>
        <p>f) Roubo de propriedades;</p>
        <p>g) Normas determinadas pela lei LGPD não sejam violadas.</p>
        <p>Estes riscos ocorrem pelos seguintes principais motivos:</p>
        <p>a) Negligência – atos não intencionais de funcionários;</p>
        <p>b) Subversão – ataques disfarçados, por funcionários;</p>
        <p>c) Acidentes – ocorrências acidentais, por fatores alheios;</p>
        <p>d) Ataques furtivos – ataques praticados por pessoas estranhas;</p>
        <p>
          e) Ataques forçados – ataques às claras, praticados por funcionários
          ou estranhos;
        </p>
        <h3>Responsabilidades</h3>
        <p>
          a) Implementar medidas e executar trabalhos contidas nessa política
          que aumentem a disponibilidade, integridade, confidencialidade e
          legalidade das informações manipuladas através de sistemas
          computadorizados de propriedade da Transporte Generoso;
        </p>
        <p>
          b) Fiscalizar periodicamente o cumprimento de regras de acesso aos
          recursos existentes nos sistemas computadorizados;
        </p>
        <p>
          c) Pesquisar e implementar, periodicamente, ferramentas e
          procedimentos, buscando garantir a integridade, disponibilidade e
          confidencialidade da informação;
        </p>
        <p>
          d) Planejar, elaborar, delegar e acompanhar os diversos planos de
          ação, visando à aderência da Transporte Generoso às diretrizes desta
          política e ao Termo de Responsabilidade;
        </p>
        <p>
          e) Verificar e validar os padrões, diretrizes e procedimentos
          operacionais necessários para garantir a segurança da informação em
          todas as áreas usuárias dos bens de informação, sob a guarda ou
          custódia da Transporte Generoso;
        </p>
        <p>
          f) Realizar periodicamente, no intervalo máximo de 90 dias uma
          verificação de vulnerabilidades dos sistemas operacionais;
        </p>
        <p>
          g) Atribuir responsabilidades de segurança da informação de cada área
          funcional da Transporte Generoso;
        </p>
        <p>
          h) Fornecer suporte e assessoria em temas de segurança da informação e
          seus controles associados;
        </p>
        <p>
          i) Apoiar a difusão da cultura de segurança da informação na Generoso
          Transportes;
        </p>
        <p>
          j) Documentar os procedimentos de operação para todos os sistemas,
          sejam eles executados em batch (execução de programas posteriormente à
          entrada das informações, normalmente fora dos horários comercias)
          on-line (execução de programas com as informações entradas no exato
          momento) ou mistos, que estiverem em produção, conforme padrões da
          Transporte Generoso; 9. Os empregados que divulgarem informações
          confidenciais da empresa por e-mail, grupos de discussão, bate-papo
          etc., não importando se a divulgação foi deliberada ou inadvertida,
          poderão sofrer as penalidades previstas na política interna da empresa
          e nas legislações trabalhista, civil e penal.
        </p>
        <p>
          l) Documentar como estão estruturados os equipamentos de
          infraestrutura, interligações das redes locais, interligações de
          servidores e softwares básicos e de apoio. A documentação oriunda
          dessa responsabilidade é suficiente a qualquer técnico habilitado
          entendê-la, possibilitando que se realize as manutenções preventivas,
          corretivas e evolutivas, no ambiente operacional.
        </p>
        <h3>Gestor da área</h3>
        <p>O Gestor da área terá a autoridade e a responsabilidade de:</p>
        <p>a) Conhecer os procedimentos de segurança em vigência;</p>
        <p>
          b) Cuidar para que seus colaboradores estejam informados e cientes de
          suas responsabilidades em relação à segurança dos bens de informação;
        </p>
        <p>
          c) Implementar os procedimentos de segurança aprovados pela empresa;
        </p>
        <p>
          d) Iniciar ação corretiva quando ocorrerem não conformidades ou quando
          sejam identificadas vulnerabilidades;
        </p>
        <p>
          e) Notificar a Equipe de Segurança da Informação as não conformidades
          de segurança.
        </p>
        <h3>Auditor de segurança</h3>
        <p>O auditor de segurança terá a autoridade e a responsabilidade de:</p>
        <p>
          a) Revisar de forma contínua os controles estabelecidos pelos
          respectivos proprietários;
        </p>
        <p>
          b) Recomendar os controles necessários para minimizar os riscos
          identificados;
        </p>
        <p>
          c) Dar assistência aos proprietários para definir uma diretriz de
          ação, quando da ocorrência de não conformidades;
        </p>
        <p>
          d) Garantir que exista uma distinção adequada de responsabilidade com
          relação à implantação e administração dos softwares de controle de
          acesso;
        </p>
        <p>
          e) Monitorar os responsáveis pela administração dos softwares de
          controle de acesso;
        </p>
        <p>
          f) Notificar a Equipe de Segurança da Informação as não conformidades
          de segurança.
        </p>
        <h3>RH</h3>
        <p>
          A área de Recursos Humanos terá a autoridade e a responsabilidade de:
        </p>
        <p>a) Em caso da admissão Obter referências profissionais;</p>
        <p>
          Obter referências profissionais; Verificar a idoneidade das
          informações do selecionado através da documentação exigida em sua
          admissão; Providenciar a assinatura do Termo de Responsabilidade;
          Entregar um login, senha inicial e perfil e acesso, quando for o caso,
          a ser fornecido pela área responsável pela política de segurança para
          utilização no ambiente de colaboração e de rede. b) Em caso de
          transferência
        </p>
        <p>
          Avisar a área responsável pela política de segurança sobre ajuste de
          perfil de acesso, quando houver transferência de setor. c) Em caso de
          demissão
        </p>
        <p>
          Avisar imediatamente a área responsável pela política de segurança a
          proceder com a remoção de todos os acessos. Emitir um termo, isentando
          o usuário de responsabilidade sobre a utilização do login que está
          sendo eliminado, após seu desligamento. d) Em caso de infração e não
          cumprimento da norma de segurança.
        </p>
        <p>
          Registrar e formalizar, perante os responsáveis, as não conformidades
          de segurança, visando medidas disciplinares, que vão desde uma simples
          advertência até a demissão por justa causa Responsabilidades
          individuais Atividades perniciosas. É proibida toda e qualquer
          tentativa deliberada de retirar o acesso à rede ou a qualquer
          computador da Transporte Generoso, ou de prejudicar o seu rendimento.
        </p>
        <p>São considerados procedimentos graves:</p>
        <p>
          Criar ou propagar ameaças digitais de qualquer espécie que venham a
          danificar serviços e arquivos; Destruir ou estragar intencionalmente
          equipamentos, software ou dados pertencentes à Transporte Generoso ou
          a outros usuários; Destituir os direitos de outros usuários; Fazer uso
          de recursos digitais pessoais de responsabilidades de outro
          colaborador; As ações acima são proibidas mesmo com o uso dos
          seguintes expedientes:
        </p>
        <p>
          Senhas especiais obtidas por quaisquer meios; Falhas nos sistemas de
          segurança dos computadores e redes; Senhas de terceiros obtidas por
          quaisquer meios; Direitos especiais de acesso já extintos com o
          término do período de ocupação de cargo na Transporte Generoso.
          Identificação das ferramentas Active Directory Responsável por
          controlar as permissões de acessos a todos os recursos compartilhados
          localmente e online.
        </p>
        <h3>Office365</h3>
        <p>
          Ambiente de colaboração em nuvem que permite o compartilhamento de
          informações e conteúdos online entre os colaborares internos e
          externos.
        </p>
        <h3>SysAid</h3>
        <p>
          Ferramenta responsável por gerenciar os incidentes e solicitações,
          através de tickets de atendimentos estabelecidos por um critério de
          SLA.
        </p>
        <h3>Skype for Business</h3>
        <p>
          Ferramenta responsável por permitir a comunicação entre os
          colaboradores e clientes através de chat, voz e vídeo conferência.
        </p>
        <h3>Benner Logistica</h3>
        <p>
          Ferramenta responsável por fazer toda a gestão logística do
          transporte.
        </p>
        <p>
          O usuário da rede tem ainda as seguintes responsabilidades A.
          Respeitar a propriedade intelectual, não copiando, modificando, usando
          ou divulgando em todo ou em parte, textos, artigos, programas ou
          qualquer outro material ou mídia protegida por copyright, sem a
          permissão expressa, por escrito, do detentor dos direitos da mesma ou
          Transporte Generoso.
        </p>
        <p>
          B. Zelar pelos equipamentos que utiliza, não sendo permitido qualquer,
          remoção, desconexão de partes, substituição ou qualquer alteração nas
          características físicas ou técnicas dos equipamentos integrantes da
          rede.
        </p>
        <p>
          C. Estar ciente de que o código de acesso à rede, senha para
          utilização dos serviços é pessoal e intransferível, devendo, portanto,
          proceder de forma responsável, garantindo o sigilo de seus códigos de
          acesso, trocando-os periodicamente e escolhendo códigos de difícil
          decodificação seguindo as recomendações.
        </p>
        <p>
          D. Compreender que computadores, recursos digitais, redes e outros
          serviços de informática deverão ter seu uso voltados exclusivamente
          para fins profissionais e que sua utilização para fins particulares ou
          para beneficiar empresas que não tenham relação com a Generoso
          Transportes, está sujeita além dos descontos legais, ao monitoramento
          por parte da STI (Segurança de Tecnologia da Informação) da Generoso
          Transportes.
        </p>
        <p>
          E. Respeitar áreas de acesso restrito, não executando tentativas de
          acesso à áreas e/ou máquinas alheias a suas permissões de acesso.
        </p>
        <p>
          F. Não desenvolver, fomentar ou promover ações que incentivem o
          racismo e não divulgar material de cunho sexual e/ou
          político-partidário. Não armazenar, distribuir, editar ou gravar
          através do uso dos recursos computacionais da rede corporativa da
          empresa quaisquer outros materiais que violem direitos constitucionais
          do cidadão.
        </p>
        <p>
          G. Não fazer uso da rede para molestar, ameaçar ou ofender os seus
          usuários ou terceiros, por qualquer meio, seja por textos, imagens,
          vídeos ou correios eletrônicos.
        </p>
        <p>H. Não fazer uso da rede para circulação de propaganda política.</p>
        <p>
          I. Não tomar atitude ou ação que possa diretamente ou indiretamente
          tornar os recursos não disponíveis.
        </p>
        <p>
          J. Não executar programas que tenham como finalidade a decodificação
          de senhas, a monitoração da rede, a leitura de dados de terceiros, a
          propagação de ameaças digitais, a destruição parcial ou total de
          arquivos ou a indisponibilidade de serviços.
        </p>
        <p>
          K. Manter seus dados atualizados e com cópias de segurança, evitando a
          perda de informações valiosas.
        </p>
        <p>
          L. Não executar programas, instalar equipamentos ou executar ações que
          possam facilitar o acesso à rede de usuários não autorizados.
        </p>
        <p>
          M. É vedada aos usuários da rede a adição de quaisquer recursos, sejam
          eles microcomputadores, impressoras, ou outros equipamentos. A adição
          de novos equipamentos por parte do usuário deve ser solicitada por
          comunicação interna via SysAid e deverá ser aprovada pelo STI
          (Segurança de Tecnologia da Informação) . Todos os equipamentos
          ligados à rede devem obedecer a padrões de instalação, de designação
          de endereços e domínio, portanto, uma vez aprovada a solicitação, será
          realizada a adição do equipamento pelo – STI (Segurança de Tecnologia
          da Informação) da Transporte Generoso. A adição de recursos à revelia
          da Transporte Generoso compromete a administração e a segurança da
          rede, assim como a assistência ao equipamento.
        </p>
        <p>
          N. Nenhum colaborador poderá utilizar os recursos da Generoso
          Transportes para fazer download ou distribuição de software ou dados
          “piratas” (sem licença/autorização de uso), sob pena de incorrer nas
          sanções previstas nas legislações trabalhista, civil e penal.
        </p>
        <p>
          O. Nenhum colaborador poderá utilizar os recursos da empresa para
          deliberadamente propagar qualquer tipo de ameaças digitais sob pena de
          igualmente submeter-se às sanções previstas nas legislações
          trabalhista, civil e penal.
        </p>
        <p>
          P. Somente o colaborador expressamente autorizado pode se inscrever em
          nome da empresa em sites de Bate-Papo (Chat Room), Grupos de Discussão
          (fóruns, newsgroups etc.) ou enviar informações eletrônicas à imprensa
          relativos à Transporte Generoso.
        </p>
        <p>
          Q. Cópia de software para quaisquer tipo de mídia (pen-drives, CD´s.,
          DVD´s etc) só poderá ser feita com autorização expressa do STI
          (Segurança de Tecnologia da Informação) da Transporte Generoso.
        </p>
        <p>
          R. Como é do interesse da empresa que os seus colaboradores
          mantenham-se bem informados, o uso de sites ou de serviços de notícias
          é aceitável, quando o seu cargo ou função permitir, desde que o seu
          uso não comprometa o uso da banda da rede nem perturbe o bom andamento
          dos trabalhos a serem realizados e tenham sido previamente autorizados
          pela STI (Segurança de Tecnologia da Informação) da Generoso
          Transportes.
        </p>
        <p>
          S. Os colaboradores poderão utilizar a Internet para atividades não
          relacionadas com as atribuições funcionais durante o expediente, desde
          que dentro das regras de uso definidas nesta política e estando
          cientes de que o conteúdo poderá ser monitorado e/ou gravado sem que
          isto represente invasão de privacidade por parte da Generoso
          Transportes.
        </p>
        <h3>Auditoria</h3>
        <p>
          Todos os usuários conectados aos recursos de rede da Generoso
          Transportes, estão sujeitos a auditoria. Os procedimentos de auditoria
          e de monitoramento de uso serão realizados periodicamente pelo Time do
          STI (Segurança de Tecnologia da Informação) ou profissional contratado
          para este fim, que terá como objetivo observar o cumprimento das
          normas deste regulamento pelos usuários e com vistas a gestão de
          performance da rede. Havendo evidência de atividade que possa
          comprometer a segurança da rede, será permitido ao administrador da
          rede auditar e monitorar as atividades de um usuário, além de
          inspecionar seus arquivos e registros de acesso, a bem do interesse da
          Transporte Generoso, sendo o fato imediatamente comunicado à Diretoria
          Administrativa e Pessoal.
        </p>
      </SectionBottom>
    </Section>
  );
};

export default SectionComponent;
