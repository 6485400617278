import styled from 'styled-components';
import { colors } from '@/styles/GlobalStyle';

export const HeroSection = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 300px;

  background-color: ${colors.blue100};

  display: flex;
  align-items: center;

  padding-left: 29px;

  h1 {
    font-weight: 400;

    width: 348px;
    line-height: 48px;
  }

  @media (max-width: 500px) {
    padding-left: 16px;
  }
`;
